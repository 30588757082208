<template>
  <div data-v-47eb5d90 class="con2_">
    <div data-v-ce543f14 data-v-47eb5d90 class="team_">
      <div data-v-7b760f82 data-v-ce543f14 class="addAccount_ form_custom_">
        <div data-v-7b760f82 class="table_">
          <div data-v-7b760f82 class="up_">
            <div data-v-7b760f82 class="title_">修改密码</div>
            <el-form
              label-width="80px"
              :model="form"
              ref="form"
              :rules="passwordFormRules"
            >
              <el-form-item label="账号">
                <el-input
                  v-model="form.account"
                  class="item"
                  disabled
                ></el-input>
              </el-form-item>
              <el-form-item label="原密码" prop="origin">
                <el-input
                  v-model="form.origin"
                  class="item"
                  show-password
                ></el-input>
              </el-form-item>
              <el-form-item label="新密码" prop="password">
                <el-input
                  v-model="form.password"
                  class="item"
                  show-password
                ></el-input>
              </el-form-item>
              <el-form-item label="确认密码" prop="conPassword">
                <el-input
                  v-model="form.conPassword"
                  class="item"
                  show-password
                ></el-input>
                <el-button
                  @click="onSubmit"
                  class="ant-btn ant-btn-primary"
                  style="margin-left: 36px;"
                  >保存</el-button
                >
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "changePassword",

  data() {
    const matchPasswordValidator = (rule, value, callback) => {
      let origin = null;
      let password = null;
      let conPassword = null;
      if (rule.field === "origin") {
        origin = value;
        password = this.form.password;
      } else if (rule.field === "password") {
        origin = this.form.origin;
        password = value;
      } else if (rule.field === "conPassword") {
        password = this.form.password;
        conPassword = value;
      }
      if (origin && password && origin === password) {
        callback(new Error("新密码和原密码不能相同"));
      }
      if (password && conPassword && !(password === conPassword)) {
        callback(new Error("确认密码和新密码不相同"));
      }
      callback();
    };
    return {
      form: {
        id: null,
        account: null,
        origin: null, // 旧密码
        password: null, //新密码
        conPassword: null // 确认密码
      },
      passwordFormRules: {
        origin: [
          { required: true, message: "请输入原密码", trigger: "blur" },
          { validator: matchPasswordValidator, trigger: "blur" }
        ],
        password: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          { min: 6, message: "密码不少于6位", trigger: "blur" },
          { validator: matchPasswordValidator, trigger: "blur" }
        ],
        conPassword: [
          { required: true, message: "请输入确认密码", trigger: "blur" },
          { validator: matchPasswordValidator, trigger: "blur" }
        ]
      }
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions("user", ["details", "changePassword"]),
    load() {
      this.user = this.$store.getters["user/userDetails"];
      this.form.id = this.user.userId;
      this.form.account = this.user.username;
    },
    async validatePasswordForm() {
      try {
        await this.$refs.form.validate();
        return true;
      } catch (e) {
        return false;
      }
    },
    async onSubmit() {
      const res = await this.validatePasswordForm();
      if (!res) {
        return;
      }
      this.changePassword({ ...this.form }).then(res => {
        if (res.data.code === 2000) {
          this.$message.success("修改成功");
        }
      });
    }
  }
};
</script>
<style src="../../../../theme/chunk-vendo.css" scoped></style>
<style src="../../../../theme/chunk.css" scoped></style>
<style src="../../../../theme/chunk_a.css" scoped></style>
<style src="../../../../theme/chunk_0.css" scoped></style>
<style src="../../../../theme/chunk_1.css" scoped></style>
<style scoped>
.item {
  width: 400px;
}
</style>
